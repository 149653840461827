import React from 'react'
import Layout from '../../components/Layout'
import * as styles from '../../styles/projects.module.css'
import react from '../../assets/react.svg'
import sass from '../../assets/sass.svg'
import node from '../../assets/node.svg'
import mongo from '../../assets/mongodb.svg'
import expo from '../../assets/expo.svg'
import bootstrap from '../../assets/bootstrap.svg'
import stripe from '../../assets/stripe.svg'
import heroku from '../../assets/heroku.svg'
import webflow from '../../assets/Webflow_logo_2023.svg'
import clickup from '../../assets/clickup.png'
import trello from '../../assets/Logo_Trello.svg'
import indesign from '../../assets/Adobe_InDesign_CC_icon.svg'
import html from '../../assets/html.svg'
import { graphql } from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';


export default function Projects() {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <h1 className="header">Projects</h1>

      <div className={styles.projects}>

        <div className={styles.project}>
          <p className={styles.title}>Mini Octopuses</p>
            <img style={{width: "5rem"}} src={react}/>
            <img style={{width: "6rem"}} src={sass}/>
            <img style={{width: "7rem"}} src={node}/>
            <img style={{width: "15rem"}} src={mongo}/>
          <a href="https://minioctopuses.herokuapp.com/" target="_blank"><button className={styles.btn}>Voir le site</button></a>
        </div>

        <div className={styles.project}>
          <p className={styles.title}>Gemai</p>
          <img style={{width: "15rem", filter: "brightness(0%)"}} src={clickup}/>
          <img style={{width: "15rem", filter: "brightness(0%)"}} src={webflow}/>
          {/*<img style={{width: "5rem"}} src={react}/>*/}
            {/*<p><img style={{width: "3rem"}} src={react}/>React Native</p>*/}
            {/*<img style={{width: "12rem"}} src={expo}/>*/}
            {/*<img style={{width: "12rem"}} src={heroku}/>*/}
          <a href="https://gemai.webflow.io/" target="_blank"><button className={styles.btn}>Voir le site</button></a>
        </div>

        <div className={styles.project}>
          <p className={styles.title}>Queen Bee</p>
            <img style={{width: "12rem", filter: "brightness(0%)"}} src={trello}/>
          <img style={{width: "7rem", filter: "grayscale(1)"}} src={indesign}/>
          {/*<img style={{width: "4rem"}} src={html}/>*/}
          {/*  <img style={{width: "10rem"}} src={bootstrap}/>*/}
          {/*  <img style={{width: "5rem"}} src={node}/>*/}
          {/*  <img style={{width: "6rem"}} src={stripe}/>*/}
          <a href="https://www.youtube.com/watch?v=1oeL-MgefR4&ab_channel=LinePeters" target="_blank"><button className={styles.btn}>Voir le vidéo</button></a>
        </div>

        <div className={styles.footer}>
          <Trans>Ce portfolio a été créé en utilisant</Trans> <span style={{fontWeight: 600}}>Gatsby</span> <Trans>et déployé avec</Trans> <span style={{fontWeight: 600}}>Firebase</span>.
        </div>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;